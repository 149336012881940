@import "app/assets/style/variables.scss";

.results {
  text-align: center;

  .response {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 5px;
  }

  @media(min-width: theme("screens.sm")) {
    display: flex;
    gap: 50px;
    justify-content: center;
  }
}

.users {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;

  .user {
    background: $blue;
    border-radius: 5px;
    color: $white;
    font-size: 0.75em;
    padding: 4px;
    text-transform: uppercase;
    width: 50px;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
}
