@import "app/assets/style/variables.scss";

.authenticate {
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 100px);
  justify-content: center;

  button {
    background: $blue;
    border-radius: 5px;
    color: $white;
    font-size: 1.5em;
    height: 100px;
    padding: 5px;
    text-transform: uppercase;
    width: 100px;

    &:active, &:focus, &:hover {
      background: $green;
      color: $white;
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  .signup {
    background: $white;
    border: 1px solid $blue;
    color: $blue;
  }
}
